import {ChangeEvent, useEffect, useState} from 'react';
import IconAirplane from "../../../../../../../components/generic/icons/bookingIcons/IconAirplane.tsx";
import {useFlightNumber} from "../../../hooks/useFlightNumber.ts";
import CustomDropdown from "../../../../../../../components/generic/CustomDropdown.tsx";
import FlightNumberList from "../../../components/FlightNumberList.tsx";
import {useToggle} from "../../../../../../../hooks/dispatch/useToggle.ts";
import useBookingInfoInput from "../../../../bookingInfoInputField/hooks/useBookingInfoInput.ts";
import useTranslation from "../../../../../../../features/translation/hooks/useTranslation.ts";
import {useAppSelector} from "../../../../../../../hooks/selector/useAppSelector.ts";
import FloatingLabelInput from "../../../../../../../components/generic/formElements/FloatingLabelInput.tsx";
import {FlightType} from "../../../../../reducers/features/flight/types/Flight.type.ts";
import ScrollToElement from "../../../../../../../features/scroll/components/ScrollToElement.tsx";

interface BookingFlightNumber {
    data: any
    formType: string,
    field: string
    isReturn?: boolean
}


import {Notification} from "@getbybus/gbb-green";

const FlightNumber = (props: BookingFlightNumber) => {
    //TODO insert translation
    const {
        data,
        field,
        formType,
        isReturn = false
    } = props;
    const flightNumber = useTranslation("booking.form.flightNumber") as string;
    const returnString = useTranslation("booking.form.return") as string;

    const {
        flightTransferForm,
        submitTimestamp
    } = useAppSelector((state) => state?.bookingForm);

    const placeholderText = isReturn ? `${returnString} ${flightNumber}` : flightNumber;

    const {
        isIntegration,
        flightNumberPlaceholder,
        displayError,
        errorMessageString,
        isDropdownOpen,
        response,
        handleSelect,
        handleChange,
        setIsDropdownOpen
    } = useFlightNumber(formType, field, flightTransferForm?.flightNumber?.value?.flightNumber, data);


    return (
        <>
            <CustomDropdown
                field={
                    <FloatingLabelInput
                        value={flightNumberPlaceholder}
                        onChange={handleChange}
                        background={'white'}
                        id={"flight-number"}
                        label={placeholderText}
                        icon={<IconAirplane/>}
                        type={"text"}
                        autoComplete={'off'}
                    />
                }
                isOpen={!data.integrations.mozio.isMozioEnabled ? false : isDropdownOpen}
                setIsOpen={setIsDropdownOpen}
            >
                <FlightNumberList handleSelectOption={handleSelect} isLoading={response.isLoading} options={response.data} isError={response.isError} isUninitialized={response.isUninitialized}/>
            </CustomDropdown>
            {displayError && !response?.isError && (
                <ScrollToElement condition={submitTimestamp}>
                    <Notification
                        isLarge
                        notificationType="red"
                    >
                        {" "}
                        {errorMessageString}{" "}
                    </Notification>
                </ScrollToElement>
            )}
            {response?.isError && !isIntegration && (
                <Notification
                    isLarge
                    notificationType="red"
                >
                    {" "}
                    {response.error.data.data.message}{" "}
                </Notification>
            )}
        </>


    );
};

export default FlightNumber;