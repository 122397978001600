import Column from "../../containers/components/Column.tsx";
import DateTimeElements from "./DateTimeElements.tsx";
import useDateAndTimeSelector from "../hooks/useDateAndTimeSelector.ts";
import Pills from "../../../components/generic/Pills.tsx";
import useTranslation from "../../translation/hooks/useTranslation.ts";

interface DateTimeSelectProps {
    selectedDate: string
    selectedTime: string
    changeDate: (value: Date) => void
    changeTime: (value: Date) => void
    submitSelect: (value: Date) => void
    customClass?: string
    dateLabel: string
    timeLabel: string
    isClosable?: boolean
    handleClose?: () => void
    minDateTime: Date
    maxDateTime?: Date
}

const DateTimeSelect = (props: DateTimeSelectProps) => {
    const {
        selectedDate,
        selectedTime,
        changeDate,
        changeTime,
        submitSelect,
        customClass = 'gb--datetime',
        isClosable,
        handleClose,
        minDateTime,
        maxDateTime
    } = props;

    const dateLabel = useTranslation("search.form.dateLabel") as string
    const timeLabel = useTranslation("search.form.timeLabel") as string

    const {
        tab,
        tabs,
        setTab,
    } = useDateAndTimeSelector(dateLabel, timeLabel)

    const handleSubmit = (value: Date = new Date(selectedDate)) => {
        if (selectedDate) {
            submitSelect(value)
        }

        if (isClosable && handleClose) {
            handleClose();
        }
    }

    // This should be an generic feature where we can pass props for onChange, labels etc.
    return (
        <div className={customClass}>
            <Column>
                <div className={'gb--datetime-header'}>
                    <Pills
                        tabs={tabs}
                        wrapperClassName="gb--toggle-container"
                        tabClassName="gb--toggle"
                        activeTabClassName="gb--toggle__active"

                    />
                </div>
                <DateTimeElements
                    selectedDate={selectedDate}
                    selectedTime={selectedTime}
                    changeDate={changeDate}
                    changeTime={changeTime}
                    toggleActive={(tab = 'time') => {
                        setTab(tab)
                    }}
                    active={tab === "time"}
                    handleSubmit={handleSubmit}
                    isClosable={isClosable}
                    handleClose={handleClose}
                    minDateTime={minDateTime}
                    maxDateTime={maxDateTime}
                />
            </Column>

        </div>
    );
};
export default DateTimeSelect;