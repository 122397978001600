import MobileOverlay from "./MobileOverlay";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import useDismissAllOverlays from "../hooks/useDismissAllOverlays";
import useTranslation from "../../translation/hooks/useTranslation.ts";
import PlaceSelect from "../../../layout/form/features/stations/components/PlaceSelect.tsx";
import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts";
import {setDeparturePlaceInput, setDestinationPlaceInput} from "../../../reducers/mobileSlice.ts";
import useIsOverlaySubtreeActive from "../hooks/useIsOverlaySubtreeActive.ts";
import {useEffect, useRef} from "react";
import useScrollToTopOnSafari from "../../../hooks/effects/useScrollToTopOnSafari.ts";
import {useScroll} from "@getbybus/gbb-green";

interface MobilePlaceInputOverlayProps {
    searchStrict?: boolean,
}

function MobilePlaceInputOverlay({searchStrict}: MobilePlaceInputOverlayProps) {
    const {
        departurePlaceInputActive,
    } = useAppSelector((state) => state?.mobile);
    const fromRef = useRef<HTMLInputElement>(null);
    const toRef = useRef<HTMLInputElement>(null);
    const {scrollToTop} = useScroll({block: "center", behavior: "instant"});

    const isOverlaySubtreeActive = useIsOverlaySubtreeActive()

    const dispatch = useAppDispatch()
    const dismissAllOverlays = useDismissAllOverlays();

    const backClick = () => {
        if (isOverlaySubtreeActive) {
            departurePlaceInputActive
                ? dispatch(setDeparturePlaceInput(false))
                : dispatch(setDestinationPlaceInput(false))
        } else {
            dismissAllOverlays()
        }
    }

    useEffect(() => {
        setTimeout(scrollToTop, 50)
    }, []);

    const {
        cityAirportStation: cityAirportStationLabel,
        pickUpLocation: pickUpLocationLabel,
        destinationLocation: destinationLocationLabel,
        from: fromLabel,
        to: toLabel,
    } = useTranslation("search.form") as Record<string, string>

    return (
        <MobileOverlay
            backClick={backClick}
            title={departurePlaceInputActive ? pickUpLocationLabel : destinationLocationLabel}
            subtitle={`${cityAirportStationLabel}...`}
        >
            {departurePlaceInputActive ? (
                <div className='search__select search__select--from'>
                    <PlaceSelect
                        inputRef={fromRef}
                        direction='from'
                        name='departure'
                        placeholder={fromLabel}
                        isOverlay={true}
                        searchStrict={searchStrict}
                        autoFocus
                    />
                </div>
            ) : (
                <div className='search__select search__select--to'>
                    <PlaceSelect
                        inputRef={toRef}
                        direction='to'
                        name='destination'
                        placeholder={toLabel}
                        isOverlay={true}
                        searchStrict={searchStrict}
                        autoFocus
                    />
                </div>
            )}
        </MobileOverlay>
    );
}

export default MobilePlaceInputOverlay;