import {errorUnknownCodes} from "../constants/errorUnknownCodes.ts"
import {PaymentIntent, StripeError} from "@stripe/stripe-js"
import useTranslation from "../../translation/hooks/useTranslation.ts"
import {useEffect, useState} from "react"
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts"
import {useNavigate} from "react-router"
import {useUrls} from "../../../routing/hooks/useUrls.ts"
import {useLazyVerifyBookingQuery} from "../../../api/reducers/apiSlice.ts"

interface UseGetStripeResponseHandlerAttributes {
    setLoader: (value: boolean) => void
}

export default function useGetStripeResponseHandler({setLoader}: UseGetStripeResponseHandlerAttributes) {
    const {bookingId, bookingVerificationKey} = useAppSelector((state) => state?.stripe)
    const {_token} = useAppSelector((state) => state?.page)
    const navigate = useNavigate()
    const {successfulBooking} = useUrls()
    const [isSuccessPayment, setIsSuccessPayment] = useState(false)
    const [stripeError, setStripeError] = useState("")
    const [query] = useLazyVerifyBookingQuery()
    const {
        searchToken
    } = useAppSelector((state) => state?.vehicleType)

    const genericError = useTranslation("base.validation.somethingWentWrong") as string
    const genericStripeError = useTranslation("booking.form.stripeError") as string

    // Handles the successful payment and verifies booking
    useEffect(() => {
        if (isSuccessPayment) {
            query({
                bookingVerificationKey: bookingVerificationKey,
                bookingId: bookingId,
                _token: _token,
                searchToken: searchToken
            }).then((response) => {
                if (response.isError) {
                    setStripeError(genericError)
                } else {
                    // if (isMobile) {
                    //     dispatch(toggleStripeMobileOverlay())
                    // }
                    navigate(successfulBooking, {
                        state: response.data,
                    })
                }
            })
        }
    }, [isSuccessPayment])

    const secureDecline = useTranslation("booking.form.threeDSecureError") as string
    const genericDecline = useTranslation("booking.form.genericDecline") as string

    const handleStripeResponse = (error: StripeError | undefined, paymentIntent: PaymentIntent | undefined) => {
        if (error) {
            setLoader(false)
            switch (true) {
                case error.code === "card_declined" &&
                    !!error.decline_code &&
                    errorUnknownCodes.includes(error.decline_code):
                    setStripeError(genericDecline)
                    break
                case error.code === "payment_intent_authentication_failure":
                    setStripeError(secureDecline)
                    break
                case error.type === "invalid_request_error":
                    setStripeError(genericStripeError)
                    break
                default:
                    setStripeError(error.message ?? genericStripeError)
                    break
            }
        } else if (paymentIntent?.status === "requires_capture") {
            setIsSuccessPayment(true)
        } else {
            setLoader(false)
            setStripeError(genericStripeError)
        }
    }

    return {
        handleStripeResponse,
        stripeError,
    }
}
