import {createSlice} from '@reduxjs/toolkit'

export const initialState = {
    id: 0,
    imagePath: '',
    maxPax: 0,
    price: 0,
    paymentMethods: {},
    type: '',
    duration: 0,
    minPax: 0,
    isIntegration: false,
    amenities: []
}

const vehicleTypeSlice = createSlice({
    name: 'vehicleType',
    initialState,
    reducers: {
        setPrice: (state, action) => (
            {...state, price: action.payload}
        ),
        setPaymentMethods: (state, action) => (
            {...state, paymentMethods: action.payload}
        ),
        setDuration: (state, action) => (
            {...state, duration: action.payload}
        ),
        setVehicleType: (state, action) => (
            {
                ...state, ...action.payload
            }
        ),
        setIsIntegration: (state, action) => (
            {...state, isIntegration: action.payload}
        ),
        clear: () => initialState,
    },
})

export const {
    setIsIntegration,
    setPrice,
    setVehicleType,
    setPaymentMethods,
    setDuration,
} = vehicleTypeSlice.actions;

export default vehicleTypeSlice.reducer