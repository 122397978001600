import useValidateForm from "../hooks/useValidateForm.ts";
import {useParams} from "react-router";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import {getStationIdFromParam} from "../../../pages/searchResults/functions/getStationIdFromParam.ts";
import {toLower} from "lodash";
import useLocale from "../../../hooks/selector/useLocale.ts";
import {LocaleType} from "../../../types/Locale.type.ts";
import getCityFromSlug from "../../../pages/searchResults/functions/getCityFromSlug.ts";
import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts";
import {getDateRequestFormat} from "../../../pages/searchResults/functions/getDateRequestFormat.ts";

export const useGenerateQueryBody = () => {
    const params = useParams()
    const locale = toLower(useLocale()) as LocaleType;
    const {
        fromPlace,
        toPlace,
        departureDate: pickUpDateTime,
        returnDate: returnPickUpDateTime,
        numberOfPassengers: numberOfPeople,
        fromStation,
        toStation
    } = useAppSelector((state) => state?.form);
    const {
        searchedWithStations,
        shouldUseStationParams
    } = useAppSelector((state) => state?.searchResults);


    // Hook used onlu for the
    const generateBody = (isSearchForm: boolean = false) => {
        if (!shouldUseStationParams || isSearchForm) {
            return ({
                startLocation: fromPlace?.placeName ?? "",
                destinationLocation: toPlace?.placeName ?? "",
                startStationId: searchedWithStations && !isSearchForm ? fromStation.id : '',
                destinationStationId: searchedWithStations && !isSearchForm ? toStation.id : '',
                locale,
                pickUpDateTime: !!pickUpDateTime ? pickUpDateTime : new Date(new Date().setHours(23, 59, 59, 999)).toISOString(),
                returnPickUpDateTime: returnPickUpDateTime ?? '',
                numberOfPeople,
                startPlaceId: fromPlace?.placeId,
                destinationPlaceId: toPlace?.placeId
            })
        } else {
            const from = getCityFromSlug(params.fromSlug as string)
            const to = getCityFromSlug(params.toSlug as string)

            return ({
                startLocation: from,
                destinationLocation: to,
                startStationId: getStationIdFromParam(params.fromSlug  as string),
                destinationStationId: getStationIdFromParam(params.toSlug  as string),
                locale,
                pickUpDateTime: !!pickUpDateTime ? pickUpDateTime : new Date(new Date(getDateRequestFormat(params.date as string) as string).setHours(23, 59, 59, 999)).toISOString(),
                returnPickUpDateTime: returnPickUpDateTime ?? '',
                numberOfPeople
            })
        }
    }

    return {
        generateBody
    }

}