import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {SerializedError} from "@reduxjs/toolkit";
import {InitialSearchRequestType} from "../../../../../api/types/InitialSearchRequest.type.ts";
import {
    usePollMozioResultsMutation,
} from "../../../../../api/reducers/apiSlice.ts";
import useLocale from "../../../../../hooks/selector/useLocale.ts";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import {useEffect} from "react";
import {maxConsequentFailedMozioFetchCount} from "../constants/maxConsequentFailedMozioFetchCount.ts";

export default function useGetMozioSearchResults(gbtDataIsLoading: boolean, gbtDataIsError: FetchBaseQueryError | SerializedError | undefined, gbtVehiclesData: any, originalArgs: InitialSearchRequestType) {
    const [getMoreMozioVehicles, mozioData] = usePollMozioResultsMutation();
    const locale = useLocale();
    const {
        unsuccessfulMozioFetchCount,
        successfulMozioFetchCount
    } = useAppSelector((state) => (state.searchResults))

    useEffect(() => {
        // if sequential mozio fetch more failed count gets to 3, stop fetching more mozio data
        if (!gbtDataIsLoading && gbtVehiclesData?.data?.integrations.mozio.moreComing && successfulMozioFetchCount !== maxConsequentFailedMozioFetchCount) {
            const timer = setTimeout(() => {
                getMoreMozioVehicles({
                    searchId: gbtVehiclesData?.data?.mozioSearchId,
                    locale,
                    originalInitialArgs: originalArgs
                })
            }, ((successfulMozioFetchCount || 2) * 1000));
            return () => clearTimeout(timer);
        }
    }, [mozioData?.isLoading, gbtDataIsLoading]);

    return {
        mozioVehiclesData: mozioData?.data,
        mozioIsLoading: mozioData?.isLoading,
        mozioIsUninitialized: mozioData?.isUninitialized,
    }
}