import {PlaceType} from "../../../../../types/Place.type.ts";
import {setFromPlace, setToPlace} from "../../../../../reducers/formSlice.ts";
import {
    dismissAllOverlays,
    setDeparturePlaceInput,
    setDestinationPlaceInput, setSingleCalendar
} from "../../../../../reducers/mobileSlice.ts";
import {useAppDispatch} from "../../../../../hooks/dispatch/useAppDispatch.ts";
import useIsOverlaySubtreeActive from "../../../../../features/mobileOverlays/hooks/useIsOverlaySubtreeActive.ts";

interface UseHandleUserPlaceSelectParams {
    name: "departure" | "destination",
    response: any,
    setIsDropdownOpen: (value: boolean) => void,
    setPlacePlaceholder: (value: string) => void,
    toRef?: HTMLInputElement,
    toPlace: PlaceType,
    fromPlace: PlaceType,
    departureDate: string,
    isOverlay: boolean
}

export default function useHandleUserPlaceSelect(params: UseHandleUserPlaceSelectParams) {
    const {
        name,
        toRef,
        toPlace,
        response,
        fromPlace,
        isOverlay,
        departureDate,
        setIsDropdownOpen,
        setPlacePlaceholder
    } = params;

    const dispatch = useAppDispatch();
    const isOverlaySubtreeActive = useIsOverlaySubtreeActive();
    const handleSelect = (option: PlaceType) => {
        setIsDropdownOpen(false);

        if (response?.data?.status !== "error") {
            setPlacePlaceholder(option.placeName)
            name === 'departure'
                ? dispatch(setFromPlace(option))
                : dispatch(setToPlace(option))
        }
        if (isOverlaySubtreeActive) {
            name === "departure"
                ? dispatch(setDeparturePlaceInput(false))
                : dispatch(setDestinationPlaceInput(false))
        }

        if (toRef) {
            toRef?.current?.focus();
        }

        if (isOverlay) {
            if (name === "departure") {
                dispatch(setDeparturePlaceInput(false));
                if (!toPlace?.placeId) {
                    setTimeout(() => {
                        dispatch(setDestinationPlaceInput(true));
                    }, 0)
                } else if (!departureDate) {
                    setTimeout(() => {
                        dispatch(setSingleCalendar(true));
                    }, 0)
                }  else if (!isOverlaySubtreeActive) {
                    setTimeout(() => {
                        dispatch(dismissAllOverlays());
                    }, 0)
                }
            } else {
                dispatch(setDestinationPlaceInput(false));

                if (!fromPlace?.placeId) {
                    setTimeout(() => {
                        dispatch(setDeparturePlaceInput(true));
                    }, 0)
                } else if (!departureDate) {
                    setTimeout(() => {
                        dispatch(setSingleCalendar(true));
                    }, 0)
                } else if (!isOverlaySubtreeActive) {
                    setTimeout(() => {
                        dispatch(dismissAllOverlays());
                    }, 0)
                }
            }
        }
    };

    return {
        handleSelect
    }
}