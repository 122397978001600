import {useDispatch} from "react-redux";
import useNavigateToBooking from "./useNavigateToBooking.ts";
import useIsMobile from "../../../../../hooks/selector/useIsMobile.ts";
import useFormPlaceValidator from "../../../hooks/useFormPlaceValidator.ts";
import {
    initialState,
    setDuration,
    setIsIntegration,
    setPaymentMethods,
    setPrepaymentRate,
    setVehicleType
} from "../../../../../reducers/vehicleTypeSlice.ts";
import {toggleIncompleteSearchFormDataOverlay} from "../../../../../reducers/mobileSlice.ts";
import VehicleType from "../../../../../types/VehicleType.type.ts";
import useCachedData from "../../../hooks/useCachedData.ts";
import useIsPageRerenderOrRedirect
    from "../../../../../features/mobileOverlays/features/incompleteSearchFormDataOverlay/hooks/useIsPageRerenderOrRedirect.ts";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";

interface VehicleTypeCardProps {
    vehicleType: VehicleType;
    duration: number;
    isIntegration: boolean;
    isAirport: boolean;
    onIncompleteSearchFormData: () => void
}

export default function useVehicleSelected(params: VehicleTypeCardProps) {
    const {
        vehicleType,
        duration,
        isIntegration,
        isAirport,
        onIncompleteSearchFormData
    } = params

    const {
        departureDate
    } = useAppSelector((state) => state?.form)

    const isPageRerenderOrRedirect = useIsPageRerenderOrRedirect()

    const {
        fetchedGbtOriginalArgs: originalArgs
    } = useCachedData(isPageRerenderOrRedirect)

    const dispatch = useDispatch()
    const {navigateToBookingForm} = useNavigateToBooking(vehicleType, duration, isIntegration, isAirport);

    const isMobile = useIsMobile()
    const {lastSearchInvalidDeparture, lastSearchInvalidDestination} = useFormPlaceValidator()

    const vehicleSelected = () => {
        if (!(lastSearchInvalidDeparture || lastSearchInvalidDestination) && departureDate) {
            navigateToBookingForm({state: {...originalArgs}})
            return;
        }

        // preset data needed for navigating to booking form (so that we can navigate user once he fills in data)
        dispatch(setVehicleType(initialState))
        dispatch(setVehicleType(vehicleType));
        dispatch(setDuration(duration))
        dispatch(setIsIntegration(isIntegration))

        if (isMobile) {
            dispatch(toggleIncompleteSearchFormDataOverlay())
        } else (
            onIncompleteSearchFormData()
        )
    }

    return {
        vehicleSelected,
    }
}