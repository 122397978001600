import CategoryScrollCta from "./CategoryScrollCta.tsx";
import {useFilterCategory} from "../hooks/useFilterCategory.ts";
import Row from "../../../../../features/containers/components/Row.tsx";
import FilterCategoryLoader from "../../../../../components/generic/loaders/FilterCategoryLoader.tsx";
import FilterCategoryList from "./FilterCategoryList.tsx";
import PoolMozioSearchResultsResponseType from "../../../../../api/types/PoolMozioSearchResultsResponse.type.ts";

interface FilterCategorySectionProps {
    isLoading: boolean,
    isMozioUninitialized: boolean,
    isMozioMoreComing: boolean,
    mozioData: PoolMozioSearchResultsResponseType
}

const FilterCategorySection = (props: FilterCategorySectionProps) => {
    const {
        isLoading,
        mozioData,
        isMozioMoreComing,
        isMozioUninitialized
    } = props;

    const {
        isMobile,
        categoryPrices,
        scrollPosition,
        handleScroll,
        containerRef,
        fetchedGbtData
    } = useFilterCategory(isLoading, isMozioUninitialized, isMozioMoreComing, mozioData);

    return (
        <div className={'gb--category-content'}>
            <Row
                className={`gb--category-wrapper ${scrollPosition ? 'gb--category-wrapper__left' : 'gb--category-wrapper__right'}`}
                scrollRef={containerRef}
            >
                {!isMobile && Object.keys(categoryPrices).length > 3 && scrollPosition > 0 && (
                    <CategoryScrollCta
                        offset={-200}
                        handleClick={handleScroll}
                        chevronOrientation={'left'}
                    />
                )}

                <FilterCategoryList
                    fetchedGbtData={fetchedGbtData}
                    containerRef={containerRef}
                    categoryPrices={categoryPrices}
                />

                {isLoading && (
                    <FilterCategoryLoader single/>
                )}

                {!isMobile && Object.keys(categoryPrices).length > 3 && scrollPosition === 0 && (
                    <CategoryScrollCta
                        offset={200}
                        handleClick={handleScroll}
                        chevronOrientation={'right'}
                    />
                )}
            </Row>
        </div>

    );
};

export default FilterCategorySection;