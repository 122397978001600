import {Row} from "@getbybus/gbb-green";
import useIsMobile from "../../../hooks/selector/useIsMobile.ts";
import useIsTablet from "../../../hooks/selector/useIsTablet.ts";
import TripInfo from "../features/tripInfo/components/TripInfo.tsx";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import useGetSearchResultsVehiclesData from "../hooks/useGetSearchResultsVehiclesData.ts";
import useNavigateToContactForm from "../../../routing/navigation/useNavigateToContactForm.ts";
import shouldNavigateToContactForm from "../functions/shouldNavigateToContactForm.ts";
import SearchResults from "./SearchResults.tsx";
import useGetMozioSearchResults from "../features/mozioResults/hooks/useGetMozioSearchResults.ts";
import noSearchResults from "../functions/noSearchResults.ts";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import NoResults from "./NoResults.tsx";
import SearchResultsLoader from "./SearchResultsLoader";
import useClearBookingFormOnNewSearch from "../../../layout/form/hooks/useClearBookingFormOnNewSearch.ts";
import {useEffect} from "react";
import {useRef} from "react";
import openDialog from "../../../features/dialog/functions/openDialog.ts";

const SearchResultsWrapper = () => {
    const isTablet = useIsTablet()
    const isMobile = useIsMobile()
    const isSmallScreen = isMobile || isTablet
    const {navigateToContactForm} = useNavigateToContactForm()
    const {clearBookingFormOnNewSearch} = useClearBookingFormOnNewSearch();

    const dialogRef = useRef<HTMLDialogElement>(null)
    const onIncompleteSearchFormData = () => {
        openDialog(dialogRef)
    }

    const {
        isUninitialized,
        isLoading,
        error,
        data,
        originalArgs
    } = useGetSearchResultsVehiclesData()

    const {
        mozioIsUninitialized,
        mozioIsLoading,
        mozioVehiclesData,
    } = useGetMozioSearchResults(isLoading, error, data, originalArgs);

    const {
        unsuccessfulMozioFetchCount,
    } = useAppSelector((state) => (state.searchResults))

    useEffect(() => {
        clearBookingFormOnNewSearch();
    }, []);

    if (isUninitialized || isLoading) {
        return (
            <SearchResultsLoader/>
        )
    }

    // the results are loaded, there is an error, and there is a flag in response which indicates that we should redirect users to Contact form
    if (shouldNavigateToContactForm(isLoading, error)) {
        navigateToContactForm(error as FetchBaseQueryError)
    }

    if (noSearchResults(data, mozioVehiclesData, mozioIsUninitialized, mozioIsLoading, unsuccessfulMozioFetchCount)) {
        return (
            <NoResults/>
        )
    }

    return (
        <Row
            justify
        >
            {!isSmallScreen && (
                <div className="gb--card gb--booking__wrapper gb--booking__preview">
                    <TripInfo
                        label={'Outbound transfer'}
                        duration={data?.data?.duration}
                        pickUpInfo={data?.data?.pickupInfo}
                        dropOffInfo={data?.data?.dropOffInfo}
                        onIncompleteSearchFormData={onIncompleteSearchFormData}
                    />
                </div>
            )}
            <SearchResults
                data={data}
                isLoading={isLoading}
                error={error}
                mozioVehiclesData={mozioVehiclesData}
                mozioIsUninitialized={mozioIsUninitialized}
                mozioIsLoading={mozioIsLoading}
                unsuccessfulMozioFetchCount={unsuccessfulMozioFetchCount}
                originalArgs={originalArgs}
                dialogRef={dialogRef}
                onIncompleteSearchFormData={onIncompleteSearchFormData}
            />
        </Row>
    )
}
export default SearchResultsWrapper
