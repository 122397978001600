import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import useNavigateToBooking
    from "../../../../../pages/searchResults/features/vehicleCard/hooks/useNavigateToBooking.ts";
import usePrepareSelectedVehicleData from "./usePrepareSelectedVehicleData.ts";
import usePrepareIsAirportFlag from "./usePrepareIsAirportFlag.ts";
import {apiSlice, useLazyInitialSearchQuery} from "../../../../../api/reducers/apiSlice.ts";
import useLocale from "../../../../../hooks/selector/useLocale.ts";
import {LocaleType} from "../../../../../types/Locale.type.ts";
import {setSearchFinishedTimestamp, setSuccessfulSubmitTimestamp} from "../../../../../reducers/formSlice.ts";
import {useAppDispatch} from "../../../../../hooks/dispatch/useAppDispatch.ts";
import useFormPlaceValidator from "../../../../../pages/searchResults/hooks/useFormPlaceValidator.ts";
import useIsPageRerenderOrRedirect from "./useIsPageRerenderOrRedirect.ts";
import {
    resetMozioSequentialUnsuccessfulFetch, setRefreshResults, setSearchedWithStations, setShouldUseStationParams
} from "../../../../../pages/searchResults/reducers/functions/searchResultsSlice.ts";
import {useEffect} from "react";
import {useLogError} from "../../../../errors/hooks/useLogError.ts";
import {getStationIdFromParam} from "../../../../../pages/SearchResults/functions/getStationIdFromParam.ts";

export default function useConfirmUpdatedFormData(closePopup: () => void, originalArgs: any) {
    const dispatch = useAppDispatch()
    const {
        toPlace,
        fromPlace,
        departureDate,
        numberOfPassengers,
        returnDate,
        fromStation,
        toStation
    } = useAppSelector((state) => (state.form))

    const locale = useLocale() as LocaleType
    const {invalidDeparture, invalidDestination} = useFormPlaceValidator()
    const invalidAddresses = invalidDestination || invalidDeparture

    const {
        duration,
        isIntegration,
    } = useAppSelector((state) => (state.vehicleType))

    const {
        refreshResults
    } = useAppSelector((state) => (state.searchResults))

    // prepare navigateToBookingForm function
    const vehicle = usePrepareSelectedVehicleData()
    const isPageRerenderOrRedirect = useIsPageRerenderOrRedirect()
    const {isAirport} = usePrepareIsAirportFlag(isPageRerenderOrRedirect)
    const {sendLog} = useLogError();
    const {navigateToBookingForm} = useNavigateToBooking(vehicle, duration, isIntegration, isAirport)
    const [
        getNewSearchResults,
        {isLoading, status, data, isError, error, originalArgs: newOriginalArgs}
    ] = useLazyInitialSearchQuery()

    useEffect(() => {
        if (isError && error) {
            sendLog({
                trace: useConfirmUpdatedFormData.name,
                url: window.location.pathname,
                message: error.data.data.message,
                info: {
                    ...newOriginalArgs
                }
            })
        }
    }, [data]);

    const confirmNewData = () => {
        if (invalidAddresses || !departureDate) {
            return;
        }

        // For non integration vehicles, just ship them to booking form
        dispatch(setSuccessfulSubmitTimestamp(new Date().getMilliseconds()))
        dispatch(setSearchedWithStations(true))
        if (!isIntegration && !refreshResults) {
            dispatch(setRefreshResults(false));
            navigateToBookingForm({state: {...originalArgs}});
            closePopup()
            dispatch(setShouldUseStationParams(false));
            getNewSearchResults({
                locale,
                pickUpDateTime: departureDate,
                numberOfPeople: numberOfPassengers,
                startStationId: fromStation.id,
                destinationStationId: toStation.id,
                returnPickUpDateTime: returnDate ?? "",
                destinationLocation: toPlace?.placeName ?? "",
                startLocation: fromPlace?.placeName ?? "",
                startPlaceId: fromPlace?.placeId,
                destinationPlaceId: toPlace?.placeId
            }).then(() => {
                dispatch(setSearchFinishedTimestamp(new Date().getMilliseconds()))
            });
            dispatch(resetMozioSequentialUnsuccessfulFetch())
            return;
        }
        // for all the integration vehicles, just update data, change modal to loader for whatever loading takes, then close modal
        // dont let them go to next phase yet
        getNewSearchResults({
            locale,
            pickUpDateTime: departureDate,
            numberOfPeople: numberOfPassengers,
            startStationId: fromStation.id,
            destinationStationId: toStation.id,
            returnPickUpDateTime: returnDate ?? "",
            destinationLocation: toPlace?.placeName ?? "",
            startLocation: fromPlace?.placeName ?? "",
            startPlaceId: fromPlace?.placeId,
            destinationPlaceId: toPlace?.placeId
        }).unwrap().then(() => {
            closePopup()
            dispatch(setSearchFinishedTimestamp(new Date().getMilliseconds()))
            dispatch(setShouldUseStationParams(false))
            apiSlice.util?.invalidateTags(["Mozio"])
            dispatch(resetMozioSequentialUnsuccessfulFetch())
        })

    }

    return {
        confirmNewData,
        isLoading,
        status,
    }
}