import FilterCategory from "./FilterCategory.tsx";
import SearchResultsResponseType from "../../../../../api/types/SearchResultsResponse.type.ts";

interface FilterCategoryListProps {
    fetchedGbtData: SearchResultsResponseType,
    categoryPrices: Record<number, number>,
}

const FilterCategoryList = (props: FilterCategoryListProps) => {
    const {
        fetchedGbtData,
        categoryPrices,
    } = props;

    return (
        <>
            {fetchedGbtData?.data?.vehicleCategories.map(vehicleCategory => {
                if (categoryPrices[vehicleCategory.id]) {
                    return (
                        <FilterCategory
                            key={vehicleCategory.id}
                            vehicleCategory={vehicleCategory}
                        />
                    )
                }
            })}
        </>
    );
};

export default FilterCategoryList;