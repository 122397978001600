import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";

export default function usePrepareSelectedVehicleData() {
    const {
        maxPax,
        minPax,
        price,
        type,
        id,
        amenities,
        imagePath,
        paymentMethods,
    } = useAppSelector((state) => (state.vehicleType));

    return {
        id,
        imagePath,
        minPax,
        maxPax,
        type,
        price,
        amenities,
        paymentMethods,
    }
}