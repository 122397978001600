import useNavigateToBookingForm from "../../../../../routing/navigation/useNavigateToBookingForm.ts";
import {NavigateOptions, useParams} from "react-router";
import VehicleType from "../../../../../types/VehicleType.type.ts";

// This is a wrapper for useNavigateToBookingForm specific for search results
// This logic is applied like this to make sure params exist
export default function useNavigateToBooking(vehicleType: VehicleType, duration: number, isIntegration: boolean, isAirport: boolean) {
    const {navigateToBookingForm: navigate} = useNavigateToBookingForm()
    const {
        fromSlug,
        toSlug,
        date,
        passengers,
    } = useParams()
    const navigateToBookingForm = (options?: NavigateOptions) => {
        navigate(fromSlug!, toSlug!, date!, passengers!, vehicleType, duration, isIntegration, isAirport, options)
    }

    return {
        navigateToBookingForm,
    }
}