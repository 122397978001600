import {
    maxConsequentFailedMozioFetchCount
} from "../features/mozioResults/constants/maxConsequentFailedMozioFetchCount.ts";

export default function noSearchResults(data: any, mozioVehiclesData: any, mozioIsUninitialized: boolean, mozioIsLoading: boolean, unsuccessfulMozioFetchCount: number) {
    const isMozioLoading = mozioIsUninitialized ? data?.data?.integrations?.mozio?.moreComing : mozioVehiclesData?.data?.moreComing
    return (
        // no GBT data (our partners)
        (
            !data?.data?.gbt
            // is not currently loading any more Mozio data
            && !(!mozioIsUninitialized || mozioIsLoading)
            // max consequent failed mozio fetch count is not reached
            && unsuccessfulMozioFetchCount !== maxConsequentFailedMozioFetchCount
            // no mozio results initially
            && !data?.data?.integrations.mozio.moreComing
        )
        // No results after loading for some time / or no more coming + no results
        || (
            ((unsuccessfulMozioFetchCount === maxConsequentFailedMozioFetchCount) || !mozioVehiclesData?.data?.moreComing)
            && !isMozioLoading
            && !data?.data?.gbt.vehicleGroups.length
            && (!mozioIsLoading || unsuccessfulMozioFetchCount === maxConsequentFailedMozioFetchCount)
        )
    )
}