import SearchResultsResponseType from "../types/SearchResultsResponse.type.ts";
import PoolMozioSearchResultsResponseType from "../types/PoolMozioSearchResultsResponse.type.ts";

export default function handleIntegrationVehicles(data: PoolMozioSearchResultsResponseType) {
    const updateGbtVehicleData = (state: SearchResultsResponseType) => {
        const sortedVehicleGroups = [
            ...state.data?.gbt?.vehicleGroups,
            ...(!!data?.data?.results ? data.data.results : [])
        ].sort((a, b) => a.price - b.price);

        return ({
            ...state,
            data: {
                ...state.data,
                gbt: {
                    ...state.data?.gbt,
                    vehicleGroups: sortedVehicleGroups
                },
                integrations: {
                    mozio: {
                        ...state.data?.integrations?.mozio,
                        moreComing: data?.data?.moreComing
                    }
                }
            }
        });
    };

    return {
        updateGbtVehicleData,
    }
}