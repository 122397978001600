import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts"
import {useHandleBookingInfo} from "./useHandleBookingInfo.ts"
import {useNavigate} from "react-router"
import {useEffect, useRef, useState} from "react"
import ReCAPTCHA from "react-google-recaptcha"
import {getTranslatedRoute} from "../../../routing/functions/getTranslatedRoute.ts"
import {
    useLazyBookingIntegrationSubmitQuery,
    useLazyBookingSubmitQuery,
    useLazyVerifyBookingQuery
} from "../../../api/reducers/apiSlice.ts"
import useTranslation from "../../../features/translation/hooks/useTranslation.ts"
import {AmenityType} from "../../../types/Amenity.type.ts";

export const useSubmitBooking = (setLoading: (flag: boolean) => void, data: any, isLoading: boolean) => {
    const navigate = useNavigate()
    const errorMessage = useTranslation("base.validation.somethingWentWrong") as string;
    const [displayError, setDisplayError] = useState(false)
    const bookingData = useAppSelector((state) => state?.bookingForm)
    const {validateAllFields} = useHandleBookingInfo()
    const recaptchaRef = useRef<ReCAPTCHA>(null)
    const [verify, responseVerify] = useLazyVerifyBookingQuery()
    const [book, responseBook] = useLazyBookingSubmitQuery()
    const [bookIntegration, responseBookIntegration] = useLazyBookingIntegrationSubmitQuery()

    const {getPath} = getTranslatedRoute()

    const translatedBooking = getPath("booking")

    const clearError = () => {
        setLoading(false)
        setDisplayError(false)
    }

    const {
        fromPlace,
        toPlace,
        departureDate,
        returnDepartureDate
    } = useAppSelector((state) => state?.form)

    const {
        _token,
        locale
    } = useAppSelector((state) => state?.page)

    const {
        id,
        isIntegration,
        amenities,
        searchToken,
        partnerId,
    } = useAppSelector((state) => state?.vehicleType)

    const handleSubmit = async (event) => {
        setLoading(true)
        event.preventDefault()
        let recaptcha

        if (bookingData.paymentTransferForm.paymentMethod.value === 1) {
            await recaptchaRef?.current?.reset()
            recaptcha = await recaptchaRef?.current?.executeAsync()
        }

        if (
            validateAllFields(data?.data?.isRoundTrip) &&
            (recaptcha || bookingData.paymentTransferForm.paymentMethod.value !== 1) &&
            departureDate &&
            data &&
            !isLoading
        ) {
            const amenitiesBody = amenities.flatMap((amenity: AmenityType) => {
                const amenityObject = bookingData.amenities[amenity.id]
                const selectedAmenities = []
                if (data.data.isRoundTrip && amenityObject.returnCount) {
                    selectedAmenities.push({
                        amenity: amenity.id,
                        quantity: amenityObject.returnCount,
                        isBLeg: 1
                    })
                }
                if (amenityObject.count) {
                    selectedAmenities.push({
                        amenity: amenity.id,
                        quantity: amenityObject.count,
                        isBLeg: 0
                    })
                }

                return selectedAmenities
            })

            const submitBody = {
                startStationId: data?.data.pickupInfo.pickUpStation.id,
                destinationStationId: data?.data.dropOffInfo.dropOffStation.id,

                // Auth
                locale: locale,
                _token: _token,
                "g-recaptcha-response": recaptcha,

                // Contact
                name: bookingData.contactInformationForm.firstName.value,
                lastName: bookingData.contactInformationForm.lastName.value,
                email: bookingData.contactInformationForm.email.value,
                mobileNumber: bookingData.contactInformationForm.phoneNumber.value,

                // Location
                pickUpAddress: fromPlace.placeName,
                dropOffAddress: toPlace.placeName,
                returnPickUpAddress: data.data.returnTrip ? toPlace.placeName : null,
                returnDropOffAddress: data.data.returnTrip ? fromPlace.placeName : null,

                // Additional
                bookingAmenities: amenitiesBody,
                comment: bookingData.comment || '',
                privacyPolicy: bookingData.privacyTransferForm.privacyPolicy.value,
                reviewMailConsent: bookingData.reviewMailConsent,
                newsletterConsent: bookingData.newsletterConsent,

                // Transfer info
                paymentMethod: bookingData.paymentTransferForm.paymentMethod.value,
                    // Name
                country: bookingData.country,
                    // ISO
                countryCode: bookingData.countryCode,
                flightNumber: bookingData.flightTransferForm.flightNumber.value.flightNumber,
                returnFlightNumber: bookingData.returnFlightTransferForm.returnFlightNumber.value.flightNumber
            }

            if (isIntegration) {
                await bookIntegration({
                    ...submitBody,
                    mozioSearchId: data?.data.mozioSearchId,
                    integration: 1,
                    integrationSearchId: data.data.mozioSearchId,
                    integrationResultId: id,
                })
            } else {
                await book({
                    ...submitBody,
                    numberOfPeople: bookingData.numberOfPeople,
                    vehicleGroup: id,
                    pickUpDate: departureDate,
                    pickUpTime: departureDate,
                    returnPickUpDate: returnDepartureDate,
                    returnPickUpTime: returnDepartureDate,
                    searchToken,
                })
            }
        } else {
            setLoading(false)
        }
    }

    // TODO this is probably not the way to do it ???? Fix it when need be
    useEffect(() => {
        const response = isIntegration ? responseBookIntegration : responseBook
        if (!response.isUninitialized && !response.isLoading) {
            if (responseBook.isError) {
                setDisplayError(true)
            } else if (!response?.data?.data?.clientSecret) {
                verify({
                    bookingVerificationKey: response.data.data?.bookingVerificationKey,
                    bookingId: response.data.data?.bookingId,
                    _token: _token,
                    searchToken: searchToken,
                })
            }
        }
    }, [responseBook, responseBookIntegration])

    useEffect(() => {
        if (!responseVerify.isUninitialized && !responseVerify.isLoading) {
            if (responseVerify.isSuccess) {
                navigate(`/en/${translatedBooking}/successful-booking`, {
                    state: responseVerify.data
                })
            } else {
                setDisplayError(true)
                setLoading(false)
            }
        }
    }, [responseVerify])

    return {
        handleSubmit,
        recaptchaRef,
        errorMessage,
        setDisplayError,
        displayError,
        clearError,
    }
}
