import VehicleCard from "../features/vehicleCard/components/VehicleCard.tsx";
import VehicleType from "../../../types/VehicleType.type.ts";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import {setCategoryPrice} from "../reducers/functions/searchResultsSlice.ts";
import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts";
import {ca} from "date-fns/locale";
import {useEffect} from "react";

interface VehicleTypeListProps {
    vehicles?: VehicleType[];
    duration: number;
    isAirport: boolean;
    isReturn: boolean;
    onIncompleteSearchFormData: () => void;
    categoryPrices: Record<number, string>
}

const VehicleCards = (props: VehicleTypeListProps) => {
    const {
        vehicles = [],
        duration,
        isAirport,
        isReturn,
        onIncompleteSearchFormData,
        categoryPrices
    } = props;

    const {
        selectedVehicleCategory,
    } = useAppSelector((state) => state?.searchResults)

    return (
        <div>
            <div>
                {vehicles.map((vehicleType) => {
                    if (!selectedVehicleCategory) {
                        return (
                            <VehicleCard
                                isReturn={isReturn}
                                isAirport={isAirport}
                                isIntegration={vehicleType.isIntegration}
                                duration={duration}
                                vehicleType={vehicleType}
                                key={vehicleType?.id}
                                onIncompleteSearchFormData={onIncompleteSearchFormData}
                                categoryPrices={categoryPrices}
                            />
                        )
                    } else if (selectedVehicleCategory && selectedVehicleCategory === vehicleType.vehicleCategoryId) {
                        return (
                            <VehicleCard
                                isReturn={isReturn}
                                isAirport={isAirport}
                                isIntegration={vehicleType.isIntegration}
                                duration={duration}
                                vehicleType={vehicleType}
                                key={vehicleType?.id}
                                onIncompleteSearchFormData={onIncompleteSearchFormData}
                            />
                        )
                    }
                })}
            </div>
        </div>
    )

};

export default VehicleCards;