import Column from "../../../../../features/containers/components/Column.tsx";
import Row from "../../../../../features/containers/components/Row.tsx";
import VehicleType from "../../../../../types/VehicleType.type.ts";
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";
import IconChevron from "../../../../../components/generic/icons/IconChevron.tsx";
import IconPax from "../../../../../components/generic/icons/IconPax.tsx";
import IconSuitcaseV2 from "../../../../../components/generic/icons/IconSuitcaseV2.tsx";
import IconFire from "../../../../../components/generic/icons/IconFire.tsx";
import useIsDesktop from "../../../../../hooks/selector/useIsDesktop.ts";

interface VehicleCardPaymentInfoProps {
    vehicleType: VehicleType;
    duration: number;
    isComplete: boolean;
    isReturn: boolean;
    isIntegration: boolean;
}

const VehicleCardPaymentInfo = ({vehicleType, isComplete, isReturn, isIntegration}: VehicleCardPaymentInfoProps) => {
    //TODO insert translation
    const oneWayTransfer = useTranslation('booking.chooseVehicle.oneWay') as string;
    const from = useTranslation('booking.chooseVehicle.from') as string;
    const returnTransfer = useTranslation('booking.title.returnTransfer') as string;
    const selectTrip = useTranslation('booking.chooseVehicle.selectTrip') as string;
    const [num, fraction] = vehicleType.price.split('.');
    const isDesktop = useIsDesktop()

    return (
        <Column className='gb--vehicle__paymentInfo'>
            <Row center justify>
                <Column>
                    <div className='gb--vehicle__price'>
                        {(isComplete || !isIntegration) ? (
                            <IconFire width={'20px'} height={'20px'}/>
                        ) : (
                            <div>
                                {from}
                            </div>
                        )}

                        <b>
                            <span style={{marginTop: 'auto', fontSize: '1.2em'}}>{num},</span>
                            <sup style={{fontSize: '.7em', paddingBottom: '.5rem'}}>{fraction}</sup>
                            <span style={{marginTop: 'auto', fontSize: 'larger'}}> &#8364;</span>
                        </b>
                    </div>
                    {!isDesktop && (
                        <div className='gb--vehicle__pax'>
                            <span>{vehicleType.maxPax}</span><IconPax/>
                            <span>{vehicleType.maxPax}</span><IconSuitcaseV2/>
                        </div>
                    )}
                    <span style={{textAlign: 'end', fontSize: "14px"}} className='gb--emphasize-inactive '>
                        {isReturn ? (returnTransfer) : (oneWayTransfer)}
                    </span>
                </Column>
            </Row>
            <Row>
                <button className='btn btn--orange-dark gb--vehicle__button'>
                    {isDesktop
                        ? (`${selectTrip}`)
                        : <IconChevron chevronOrientation={"right"} fill={'white'}/>
                    }
                </button>
            </Row>
        </Column>
    );
}

export default VehicleCardPaymentInfo;