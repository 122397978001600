import Loader from "../../../../../components/generic/loaders/Loader.tsx";
import IconAirplane from "../../../../../components/generic/icons/bookingIcons/IconAirplane.tsx";
import {FlightType} from "../../../reducers/features/flight/types/Flight.type.ts";
import Notification from "../../../../../features/notification/components/Notification.tsx";
import Column from "../../../../../features/containers/components/Column.tsx";


interface FlightNumberListProps {
    isLoading: boolean,
    options: any | null,
    handleSelectOption?: (value: any) => void,
    isError: boolean
    isUninitialized: boolean
}

function FlightNumberList(props: FlightNumberListProps) {
    const {
        isLoading,
        options,
        handleSelectOption,
        isError,
        isUninitialized
    } = props;

    if (isLoading) {
        return (
            <Loader small/>
        )
    }

    if (!options?.data.length && !isError && !isUninitialized) {
        return (
            <Notification additionalClass="station-select--no-results" notificationType="orange">
                no results
            </Notification>
        )
    }


    if (options?.data.length && !isError && !isLoading) {
        return (
            options?.data?.map((option: FlightType) => (
                <div
                    className="gb--select-dropdown__places"
                    key={option.flightNumber}
                    onClick={() => {
                        if (handleSelectOption) {
                            handleSelectOption(option)
                        }
                    }}
                >
                    <div>
                        <IconAirplane />
                    </div>
                    <Column>
                        <div>
                            {option.flightInfo ? option.flightInfo.split('/')[0] : option}
                        </div>
                        <div>
                            {option.flightInfo
                                ? `${option.flightInfo.split('/')[1]} /  ${option.flightInfo.split('/')[2]} / ${option.flightInfo.split('/')[3]}`
                                : ''}
                        </div>
                    </Column>
                </div>
            ))
        )
    }
}

export default FlightNumberList