import CollapsableComponent from "../../../../../components/generic/CollapsableComponent.tsx";
import Row from "../../../../../features/containers/components/Row.tsx";
import Column from "../../../../../features/containers/components/Column.tsx";
import FlightNumber from "../features/FlightNumber/components/FlightNumber.tsx";
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";

interface BookingFlightNumber {
    data: any
}

const BookingFlightNumber = (props: BookingFlightNumber) => {
    const flightNumberLabel = useTranslation("booking.form.flightNumberLabel") as string;
    const requiredLabel = useTranslation("booking.form.required") as string;
    const {
        isIntegration
    } = useAppSelector((state) => state?.vehicleType);
    const {
        data
    } = props;

    const sectionLabel = isIntegration ? `${flightNumberLabel} (${requiredLabel})` : flightNumberLabel;

    return (
        <div className={'gb--booking-item'}>
            <CollapsableComponent label={sectionLabel} hrClassName={'gb--divider__hr'}>
                <Row
                    justify
                    className="gb--booking__input"
                >
                    <Column  className="gb--booking__container">
                        <FlightNumber
                            field={"flightNumber"}
                            formType={"flightTransferForm"}
                            data={data}
                        />
                    </Column>
                    <Column className="gb--booking__container">
                        {data.isRoundTrip && (
                            <FlightNumber
                                field={"returnFlightNumber"}
                                formType={"returnFlightTransferForm"}
                                data={data}
                                isReturn
                            />
                        )}
                    </Column>
                </Row>
            </CollapsableComponent>
        </div>
    );
};

export default BookingFlightNumber;